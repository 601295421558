import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";
import _ from "lodash";

export default function TimeMachineDefaultImagesSelector(props) {
    const [filter, setFilter] = useState("");
    const [filteredDefaultImageRules, setFilteredDefaultImageRules] = useState([]);

    useEffect(function updateFilteredDefaultImages() {
        console.log("TimeMachineDefaultImagesSelector.updateFilteredDefaultImages: instance limits: ", props.instanceLimits, "editable instances: ", props.editableInstances);
        const updatedFilteredDefaultImages = [];
        for (const defaultImage of props.defaultImageRules) {
            console.log("TimeMachineDefaultImagesSelector.updateFilteredDefaultImages: checking rule with show attrs: ", defaultImage.show_attrs);
            const isViewable = props.instanceLimits === false || _.intersection(props.instanceLimits, defaultImage.show_attrs.feed_id).length > 0;
            console.log("TimeMachineDefaultImagesSelector.updateFilteredDefaultImages: checking rule with show attrs: ", defaultImage.show_attrs, "intersectionlength? ", _.intersection(props.instanceLimits, defaultImage.show_attrs.feed_id).length, props.instanceLimits, defaultImage.show_attrs.feed_id);
            const isEditable = props.editableInstances === false || _.intersection(props.editableInstances, defaultImage.show_attrs.feed_id).length > 0;
            const isNotFilteredOut = JSON.stringify(defaultImage).toString().toLowerCase().includes(filter);
            console.log(`TimeMachineDefaultImagesSelector.updateFilteredDefaultImages: is viewable: ${isViewable}; is editable: ${isEditable}; is not filtered out: ${isNotFilteredOut}`);
            if (isNotFilteredOut && isViewable) {
                updatedFilteredDefaultImages.push(Object.assign({}, defaultImage, {editable: isEditable, viewable: isViewable}));
            }
        }

        console.log("TimeMachineDefaultImagesSelector.updateFilteredDefaultImages: updated filtered default images: ", updatedFilteredDefaultImages);
        setFilteredDefaultImageRules(updatedFilteredDefaultImages);
    }, [filter, JSON.stringify(props.defaultImageRules), JSON.stringify(props.instanceLimits), JSON.stringify(props.editableInstances)]);

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <ContingentButton
                                icon
                                secondary
                                onClick={() => props.setNewClicked(true)}
                                fluid
                                service={props.service}
                                module={props.module}
                                scope="any"
                                allPermissions={props.permissions}
                                user={props.user}
                            ><Icon name="plus"/> Create New</ContingentButton>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.setIsInstructionsModalOpen(true)} fluid><Icon name="help"/> Help</Button>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshDefaultImageRules()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72vh", overflowY: "auto"}}>
                    {
                        props.loadingDefaultImages ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>
                                    Loading default image rules...
                                </Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                {
                                    filteredDefaultImageRules.length > 0 ?
                                        filteredDefaultImageRules.map((rule, index) => {
                                            console.log("filtered default image rule: ", rule)
                                            return <Menu.Item
                                                active={rule.index === props.selectedDefaultImageRule.index}
                                                disabled={rule.viewable === false}
                                                onClick={
                                                    () => {
                                                        console.log("TimeMachineDefaultImageSelector.onClick: new selected rule: ", rule);
                                                        props.setSelectedDefaultImageRule(Object.assign({}, rule));
                                                        props.setNewClicked(false);
                                                    }
                                                }
                                                fluid
                                            >
                                                <Grid>
                                                    <Grid.Row verticalAlign="middle">
                                                        <Grid.Column width={16}>
                                                            <h5>{rule.index === props.updatedSelectedDefaultImageRule.index ? props.updatedSelectedDefaultImageRule.name || "" : rule.name}</h5>
                                                            <>{rule.index === props.updatedSelectedDefaultImageRule.index ? props.updatedSelectedDefaultImageRule.description || "" : rule.description}</>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Menu.Item>
                                        }

                                        ) :
                                        <Message icon color="yellow">
                                            <Icon name="warning" />
                                            <Message.Content>No default image rules match your current search filter.</Message.Content>
                                        </Message>
                                }
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};
