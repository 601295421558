import React, {useEffect, useMemo, useState} from "react";
import {Button, Form, Grid, Icon, Input, Segment, Select} from "semantic-ui-react";

import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import {useHistory, useLocation} from "react-router";

const CATEGORIES = ["image", "metadata"]

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};
const INITIAL_LOADING_TIME = Date.now();
const ONE_DAY_IN_MS = 86400000;

export default function TimeMachineCMSAuditMasterDetailView(props) {
    const [selectedShowId, setSelectedShowId] = useState("");
    const [selectedExternalId, setSelectedExternalId] = useState("");
    const [selectedTitleId, setSelectedTitleId] = useState("");
    const [selectedPropEp, setSelectedPropEp] = useState("");
    const [selectedSeasonId, setSelectedSeasonId] = useState("");
    const [selectedSeriesId, setSelectedSeriesId] = useState("");
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("");
    const [selectedEditor, setSelectedEditor] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedStartTime, setSelectedStartTime] = useState(INITIAL_LOADING_TIME - ONE_DAY_IN_MS);
    const [selectedEndTime, setSelectedEndTime] = useState(INITIAL_LOADING_TIME + ONE_DAY_IN_MS);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [auditData, setAuditData] = useState([]);
    const [searching, setSearching] = useState(false);
    const history = useHistory();
    const {search} = useLocation();

    useMemo(() => {
        const searchParams = new URLSearchParams(search);
        if (searchParams.has("showId")) {
            setSelectedShowId(searchParams.get("showId"));
        }

        if (searchParams.has("externalId")) {
            setSelectedExternalId(searchParams.get("externalId"));
        }

        if (searchParams.has("titleId")) {
            setSelectedTitleId(searchParams.get("titleId"));
        }

        if (searchParams.has("propEp")) {
            setSelectedPropEp(searchParams.get("propEp"));
        }

        if (searchParams.has("seasonId")) {
            setSelectedSeasonId(searchParams.get("seasonId"));
        }

        if (searchParams.has("seriesId")) {
            setSelectedSeriesId(searchParams.get("seriesId"));
        }

        if (searchParams.has("franchiseId")) {
            setSelectedFranchiseId(searchParams.get("franchiseId"));
        }

        if (searchParams.has("editor")) {
            setSelectedEditor(searchParams.get("editor"));
        }

        if (searchParams.has("category")) {
            setSelectedCategory(searchParams.get("category"));
        }

        if (searchParams.has("startTime")) {
            setSelectedStartTime(parseInt(searchParams.get("startTime")) * 1000);
        }

        if (searchParams.has("endTime")) {
            setSelectedEndTime(parseInt(searchParams.get("endTime")) * 1000);
        }

        console.log("search params: ", searchParams);

        const updatedCategoryOptions = [];
        for (const category of CATEGORIES) {
            updatedCategoryOptions.push({key: category, text: category, value: category});
        }
        setCategoryOptions(updatedCategoryOptions);
        }, [search]);

    useEffect(function initialize() {
        TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);
        getAuditData();
    }, []);

    const getAuditData = () => {
        console.log("start time: ", selectedStartTime, "end time: ", selectedEndTime);
        const startTime = selectedStartTime !== null ? parseInt(selectedStartTime / 1000) : null;
        const endTime = selectedEndTime !== null ? parseInt(selectedEndTime / 1000) : null;

        const urlSearchParams = new URLSearchParams({
            ...selectedShowId.length > 0 && {
                showId: selectedShowId,
            },
            ...selectedExternalId.length > 0 && {
                externalId: selectedExternalId,
            },
            ...selectedTitleId.length > 0 && {
                titleId: selectedTitleId
            },
            ...selectedPropEp.length > 0 && {
                propEp: selectedPropEp
            },
            ...selectedSeasonId.length > 0 && {
                seasonId: selectedSeasonId
            },
            ...selectedSeriesId.length > 0 && {
                seriesId: selectedSeriesId
            },
            ...selectedFranchiseId.length > 0 && {
                franchiseId: selectedFranchiseId
            },
            ...selectedEditor.length > 0 && {
                editor: selectedEditor
            },
            ...selectedCategory.length > 0 && {
                category: selectedCategory
            },
            ...startTime !== null && {
                startTime
            },
            ...endTime !== null && {
                endTime
            }
        });

        history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });

        setSearching(true);
        TimeMachineDataProvider.cmsAudit(selectedShowId, selectedExternalId, selectedTitleId, selectedPropEp, selectedSeasonId, selectedSeriesId, selectedFranchiseId, selectedEditor, selectedCategory, selectedStartTime, selectedEndTime).then(response => {
            console.log(response);
            if (response.hasOwnProperty("error")) {
                console.error(response.error);
            }

            const updatedAuditData = [];
            let scope, scope_id;
            for (const audit of response) {
                if (audit.hasOwnProperty("show_id")) {
                    scope = "Show by Show Id";
                    scope_id = audit.show_id;
                } else if (audit.hasOwnProperty("external_id")) {
                    scope = "Show by External Id";
                    scope_id = audit.external_id;
                } else if (audit.hasOwnProperty("title_id")) {
                    scope = "Title by Title Id";
                    scope_id = audit.title_id;
                } else if (audit.hasOwnProperty("prop_ep")) {
                    scope = "Title by Prop Ep";
                    scope_id = audit.prop_ep;
                } else if (audit.hasOwnProperty("season_id")) {
                    scope = "Season";
                    scope_id = audit.season_id;
                } else if (audit.hasOwnProperty("series_id")) {
                    scope = "Series";
                    scope_id = audit.series_id;
                } else if (audit.hasOwnProperty("franchise_id")) {
                    scope = "Franchise";
                    scope_id = audit.franchise_id;
                } else {
                    console.error("Unexpected audit format: ", audit);
                    continue;
                }
                console.log(`setting scope to ${scope} and scope id to ${scope_id}`)
                updatedAuditData.push(Object.assign({}, audit, {scope, scope_id}));
            }

            setAuditData(updatedAuditData);
        }).catch(error => {
            props.toast("There was an error getting audit data.", "error");
            console.error(error);
        }).finally(() => {
            setSearching(false);
        });
    };

    const clearFilters = () => {
        setSelectedShowId("");
        setSelectedExternalId("");
        setSelectedTitleId("");
        setSelectedPropEp("");
        setSelectedSeasonId("");
        setSelectedSeriesId("");
        setSelectedFranchiseId("");
        setSelectedEditor("");
        setSelectedCategory("");
        setSelectedStartTime(INITIAL_LOADING_TIME - ONE_DAY_IN_MS);
        setSelectedEndTime(INITIAL_LOADING_TIME + ONE_DAY_IN_MS);
    }

    const searchClicked = () => {
        getAuditData();
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form widths={16}>
                        <Form.Field>
                            <Button color="blue" onClick={searchClicked} fluid><Icon name="search" />&nbsp;Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <Button fluid color="black" onClick={clearFilters}><Icon name="trash" />&nbsp;Clear Filters</Button>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field
                            control={Input}
                            label="Show ID"
                            value={selectedShowId}
                            onChange={(event, {value}) => setSelectedShowId(value)}
                        />
                        <Form.Field
                            control={Input}
                            label="External ID"
                            value={selectedExternalId}
                            onChange={(event, {value}) => setSelectedExternalId(value)}
                        />
                        <Form.Field
                            label="Title ID"
                            control={Input}
                            value={selectedTitleId}
                            onChange={(event, {value}) => setSelectedTitleId(value)}
                        />
                        <Form.Field
                            control={Input}
                            label="PropEp"
                            value={selectedPropEp}
                            onChange={(event, {value}) => setSelectedPropEp(value)}
                        />
                        <Form.Field
                            label="Season ID"
                            control={Input}
                            value={selectedSeasonId}
                            onChange={(event, {value}) => setSelectedSeasonId(value)}
                        />
                        <Form.Field
                            label="Series ID"
                            control={Input}
                            value={selectedSeriesId}
                            onChange={(event, {value}) => setSelectedSeriesId(value)}
                        />
                        <Form.Field
                            label="Franchise ID"
                            control={Input}
                            value={selectedFranchiseId}
                            onChange={(event, {value}) => setSelectedFranchiseId(value)}
                        />
                        <Form.Field
                            label="Editor"
                            control={Input}
                            value={selectedEditor}
                            onChange={(event, {value}) => setSelectedEditor(value)}
                        />
                        <Form.Field
                            label="Category"
                            control={Select}
                            value={selectedCategory}
                            onChange={(event, {value}) => setSelectedCategory(value)}
                            options={categoryOptions}
                            clearable
                        />
                        <Form.Field>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <label>Start Time</label>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="start"
                                    value={selectedStartTime}
                                    onChange={setSelectedStartTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                        <Form.Field width={16}>
                            <label>End Time</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    inputVariant="outlined"
                                    fullWidth
                                    id="end"
                                    value={selectedEndTime}
                                    onChange={setSelectedEndTime}
                                />
                            </MuiPickersUtilsProvider>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={13} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Grid.Row>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Scope",
                                    accessor: "scope",
                                    Cell: row => row.value,
                                    filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                },
                                {
                                    Header: "Scope ID",
                                    accessor: "scope_id",
                                    Cell: row => row.value,
                                    filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                },
                                {
                                    Header: "Editor",
                                    accessor: "editor",
                                    Cell: row => row.value,
                                    filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                },
                                {
                                    Header: "Category",
                                    accessor: "category",
                                    Cell: row => row.value,
                                    filterMethod: (filter, rows) => rows[filter.id] && rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
                                },
                                {
                                    Header: "Last Updated",
                                    accessor: "lastUpdated",
                                    filterable: false,
                                    defaultSort:"desc",
                                    Cell: row => {
                                        return <span>{new Date(row.original.lastUpdated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                    }
                                },
                                {
                                    accessor: "overrides",
                                    show: false
                                }
                            ]}
                            data={auditData}
                            loading={searching}
                            filterable={true}
                            defaultSorted={[
                                {id: "lastUpdated", desc: true}
                            ]}
                            SubComponent={row => <Segment><pre>{JSON.stringify(row.original, null, 4)}</pre></Segment>}
                            defaultPageSize={50}
                            onPageChange={() => {
                                document.getElementsByClassName("rt-thead")[0].scrollIntoView();
                            }}
                        />
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};
