import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Icon, Message, Modal, Table} from "semantic-ui-react";
import {FilePond} from "react-filepond";

export default function TimeMachineDefaultImagesUpdateImageModal(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [imageId, setImageId] = useState("");

    useEffect(function onUpdateImageMetadata() {
        setImageId(props.imageMetadata.imageId);
    }, [JSON.stringify(props.imageMetadata)]);

    const onClose = () => {
        setSelectedImage(null);
        props.onCloseModal();
    }

    const saveClicked = () => {
        console.log("attempting to save selected image: ", selectedImage);
        const fileFormat = selectedImage[0].fileExtension;
        let reader = new FileReader();
        reader.readAsDataURL(selectedImage[0].source);
        reader.onload = function () {
            setUploadingImage(true);
            props.TimeMachineDataProvider.defaultUploadImage(imageId, fileFormat, reader.result).then(response => {
                console.log(response);
                if (!response.hasOwnProperty("succeeded") || !response.succeeded) {
                    console.error(response);
                }
                if (response.url) {
                    console.log("updating image to use url: ", response.url);
                    props.updateImage(props.selectedLanguage, imageId, response.url);
                }

                setSuccessMessage("The selected image has been successfully uploaded.");
            }).catch(error => {
                setErrorMessage("There was an error saving the selected image.");
                console.error(error);
            }).finally(() => {
                setUploadingImage(false);
                onClose();
            });
        }
    };

    return (
        <Modal
            size="large"
            open={props.isModalOpen}
            onOpen={props.onOpenModal}
            onClose={() => onClose()}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeIcon
            style={{position: "relative"}}
        >
            <Modal.Header>Upload Image</Modal.Header>
            <Modal.Content>
                {
                    uploadingImage ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading />
                            <Message.Content>Uploading image...</Message.Content>
                        </Message> : ""
                }
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Image Name</Table.HeaderCell>
                                        {
                                            Object.keys(props.imageMetadata).filter(key => !["name", "imageId", "url"].includes(key)).map(key => (
                                                <Table.HeaderCell>{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</Table.HeaderCell>
                                            ))
                                        }
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{props.imageMetadata.name ?? ""}</Table.Cell>
                                        {
                                            Object.keys(props.imageMetadata).filter(key => !["name", "imageId", "url"].includes(key)).map(key => (
                                                <Table.Cell>{props.imageMetadata[key]}</Table.Cell>
                                            ))
                                        }
                                    </Table.Row>
                                </Table.Header>
                            </Table>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="App">
                                    <FilePond
                                        files={selectedImage}
                                        onupdatefiles={setSelectedImage}
                                        allowMultiple={false}
                                        name="files"
                                        labelIdle="Drag & Drop your image or <span class='filepond--label-action'>Browse</span>"
                                        acceptedFileTypes={["image/*", "image/avif"]}
                                        credits={false}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                <Container fluid>
                    <Button disabled={!selectedImage} floated="right" positive onClick={saveClicked}>Save</Button>
                    <Button onClick={onClose} floated="right" color="black">{successMessage ? "Exit" : "Cancel"}</Button>
                </Container>
            </Modal.Content>
        </Modal>
    );
};
