import React, {useState, useMemo, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import TimeMachineDefaultImagesSelector from "./TimeMachineDefaultImagesSelector";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import TimeMachineDefaultImagesDetailView from "./TimeMachineDefaultImagesDetailView";
import TimeMachineDefaultImagesHelpModal from "./TimeMachineDefaultImagesHelpModal";
import _ from "lodash";

export default function TimeMachineDefaultImagesMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});
    const [defaultMetadata, setDefaultMetadata] = useState({});
    const [originalDefaultImageRules, setOriginalDefaultImageRules] = useState([]);
    const [defaultImageRules, setDefaultImageRules] = useState([]);
    const [selectedDefaultImageRule, setSelectedDefaultImageRule] = useState({});
    const [updatedSelectedDefaultImageRule, setUpdatedSelectedDefaultImageRule] = useState({});
    const [instances, setInstances] = useState([]);
    const [instanceLimits, setInstanceLimits] = useState([]);
    const [editableInstances, setEditableInstances] = useState([]);
    const [sports, setSports] = useState([]);
    const [leagueNames, setLeagueNames] = useState([]);
    const [tournamentNames, setTournamentNames] = useState([]);
    const [imageSpecs, setImageSpecs] = useState({});
    const [allNames, setAllNames] = useState([]);
    const [allAttrs, setAllAttrs] = useState([]);
    const [newClicked, setNewClicked] = useState(false);
    const [loadingDefaultImages, setLoadingDefaultImages] = useState(false);
    const [savingDefaultImages, setSavingDefaultImages] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [userCanEditInstance, setUserCanEditInstance] = useState(false);
    const [imageNotFoundUrl, setImageNotFoundUrl] = useState("");
    const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);

    TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

    useEffect(function updateInstanceLimits() {
        let updatedInstanceLimits = [];
        let updatedEditableInstances = [];
        for (const permission of props.userPermissions) {
            if (permission.urn === "urn:all:aspen-power") {
                setInstanceLimits(false);
                setEditableInstances(false);
                return;
            } else if (permission.service === props.service && permission.module === props.module) {
                if (permission.scope === "all") {
                    updatedInstanceLimits = false;
                    if (permission.role === "editor") {
                        updatedEditableInstances = false;
                    }
                }
                if (updatedInstanceLimits !== false) {
                    updatedInstanceLimits.push(permission.scope);
                }
                if (permission.role === "editor"  && updatedEditableInstances !== false) {
                    updatedEditableInstances.push(permission.scope);
                }
            }
        }

        setInstanceLimits(updatedInstanceLimits);
        setEditableInstances(updatedEditableInstances);
    }, [JSON.stringify(props.userPermissions)]);

    const onClickRefresh = () => {
        refreshDefaultImageRules();
        setSelectedDefaultImageRule({});
        setUpdatedSelectedDefaultImageRule({});
    };

    const refreshDefaultImageRules = () => {
        if (newClicked) {
            setNewClicked(false);
        }

        setErrorMessage("");
        setSuccessMessage("");
        setLoadingDefaultImages(true);
        TimeMachineDataProvider.getWhitelistedMetadata().then(response => {
            if (response.hasOwnProperty("error")) {
                setErrorMessage("There was an error retrieving the default images.");
                console.error("TimeMachineDefaultImagesMasterDetailView.refreshDefaultImageRules: ", response.error);
                throw new Error(response.error);
            }

            setMetadata(response);
        }).finally(() => {
            setLoadingDefaultImages(false);
        });
    };

    useEffect(function onMetadataUpdate() {
        const updatedInstances = Object.keys(metadata).filter(instance => instance !== "default");
        setDefaultMetadata(metadata?.default ?? {});
        console.log("TimeMachineDefaultImagesMasterDetailView.onMetadataUpdate: updated instances: ", updatedInstances, "instances: ", Object.keys(metadata));
        setInstances(updatedInstances);
    }, [JSON.stringify(metadata)]);

    const updateDefaultImageRules = () => {
        setErrorMessage("");
        setSuccessMessage("");
        setSavingDefaultImages(true);
        TimeMachineDataProvider.cmsSaveDefaultImages(defaultImageRules).then(response => {
            if (response.hasOwnProperty("error")) {
                console.error("TimeMachineDefaultImagesMasterDetailView.updateDefaultImageRules: ", response.error);
                setErrorMessage("There was an error saving the default image updates.");
                return;
            }
            refreshDefaultImageRules();
            setSuccessMessage("Default image rules were successfully updated.");
        }).catch(error => {
            console.error(error);
            setErrorMessage("There was an error saving the default image updates.");
        }).finally(() => {
            setSavingDefaultImages(false);
        });
    };

    useMemo(() => {
        refreshDefaultImageRules();
    }, []);

    useEffect(function onUpdateSelectedDefaultRule() {
        console.log("TimeMachineDefaultImagesMasterDetailView.onUpdateSelectedDefaultRule: selected default image rule was changed, is it new?", selectedDefaultImageRule.is_new === true)
        if (newClicked && selectedDefaultImageRule.is_new !== true) {
            setNewClicked(false);
        }
        setErrorMessage("");
        setSuccessMessage("");
    }, [JSON.stringify(selectedDefaultImageRule)]);

    useMemo(function updateUserCanEdit() {
        let updatedUserCanEdit = false;
        for (const permission of props.userPermissions) {
            const userHasAspenPower = permission.service === "all" && permission.module === "all" && permission.scope === "all" && permission.role === "aspen-power";
            const userHasRelevantPermission = permission.service === props.service && permission.module === props.module && permission.role === "editor";
            updatedUserCanEdit ||= userHasAspenPower || userHasRelevantPermission;
            if (updatedUserCanEdit) {
                break;
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    useMemo(function updateUserCanEditInstance() {
        const userCanEditRuleInstances = editableInstances === false || (selectedDefaultImageRule.hasOwnProperty("show_attrs") && _.intersection(editableInstances, selectedDefaultImageRule.show_attrs.feed_id).length > 0);
        setUserCanEditInstance(newClicked || userCanEditRuleInstances);
    }, [JSON.stringify(editableInstances), JSON.stringify(selectedDefaultImageRule)]);

    useMemo(function loadFromMetadata() {
        console.log("TimeMachineDefaultImagesMasterDetailView.loadFromMetadata: effect: loading from metadata")
        const updatedDefaultImageRules = [];
        if (defaultMetadata.hasOwnProperty("default_images")) {
            for (let i = 0; i < defaultMetadata.default_images.length; i++) {
                updatedDefaultImageRules.push(Object.assign({}, defaultMetadata.default_images[i], {index: i, is_new: false}));
            }
        }
        setDefaultImageRules(updatedDefaultImageRules.slice());
        setOriginalDefaultImageRules(updatedDefaultImageRules.slice());

        let updatedImageSpecs = {};
        if (defaultMetadata.hasOwnProperty("image_specs")) {
            updatedImageSpecs = Object.assign({}, defaultMetadata.image_specs);
        }
        setImageSpecs(updatedImageSpecs);

        setImageNotFoundUrl(defaultMetadata.hasOwnProperty("image_not_found_url") ? defaultMetadata.image_not_found_url : "https://akamai.warnermediacdn.com/inflow/lower/18c8d64c-9081-4a3d-b15a-a2cdcd6ef19e.jpeg");

        const updatedSports = [];
        const updatedLeagueNames = [];
        const updatedTournamentNames = [];
        if (defaultMetadata.hasOwnProperty("cms_options")) {
            console.log("TimeMachineDefaultImagesMasterDetailView.loadFromMetadata: cms options: ", defaultMetadata.cms_options);
            if (defaultMetadata.cms_options.hasOwnProperty("sport") && Array.isArray(defaultMetadata.cms_options.sport)) {
                for (const sport of defaultMetadata.cms_options.sport) {
                    updatedSports.push(sport);
                }
            }

            if (defaultMetadata.cms_options.hasOwnProperty("league_name") && Array.isArray(defaultMetadata.cms_options.league_name)) {
                for (const leagueName of defaultMetadata.cms_options.league_name) {
                    updatedLeagueNames.push(leagueName);
                }
            }

            if (defaultMetadata.cms_options.hasOwnProperty("tournament_name") && Array.isArray(defaultMetadata.cms_options.tournament_name)) {
                for (const tournamentName of defaultMetadata.cms_options.tournament_name) {
                    updatedTournamentNames.push(tournamentName);
                }
            }
        }

        setSports(updatedSports);
        setLeagueNames(updatedLeagueNames);
        setTournamentNames(updatedTournamentNames);
    }, [JSON.stringify(defaultMetadata)]);

    useEffect(() => {
        let updatedSelectedDefaultImageRule = {};
        if (Object.keys(selectedDefaultImageRule).length > 0) {
            for (const rule of defaultImageRules) {
                if (rule.name === selectedDefaultImageRule.name) {
                    updatedSelectedDefaultImageRule = Object.assign({}, rule);
                }
            }
        }

        setSelectedDefaultImageRule(updatedSelectedDefaultImageRule);
        setUpdatedSelectedDefaultImageRule(updatedSelectedDefaultImageRule);
    }, [JSON.stringify(defaultImageRules)]);

    useEffect(function onDefaultImageRulesUpdate() {
        console.log("TimeMachineDefaultImagesMasterDetailView.onDefaultImageRulesUpdate: effect: default image rules updated", defaultImageRules);

        const updatedAllNames = [];
        const updatedAllAttrs = [];

        const selectedDefaultImageRuleShowAttrs = Object.assign({}, selectedDefaultImageRule.show_attrs);
        for (const key in selectedDefaultImageRuleShowAttrs.show_attrs) {
            selectedDefaultImageRuleShowAttrs[key] = selectedDefaultImageRuleShowAttrs.show_attrs[key].slice().sort();
        }
        for (const rule of defaultImageRules) {
            if (rule.name && rule.name !== selectedDefaultImageRule.name) {
                console.log(`TimeMachineDefaultImagesMasterDetailView.onDefaultImageRulesUpdate: adding ${rule.name} to default images rules because it doesn't match the rule ${selectedDefaultImageRule.name}`, rule, selectedDefaultImageRule);
                updatedAllNames.push(rule.name || "");
            } else {
                console.log(`TimeMachineDefaultImagesMasterDetailView.onDefaultImageRulesUpdate: skipping ${rule.name} because it matches the rule ${selectedDefaultImageRule.name}`, rule, selectedDefaultImageRule);
            }

            const ruleShowAttrs = Object.assign({}, rule.show_attrs);
            for (const key in rule.show_attrs) {
                ruleShowAttrs[key] = ruleShowAttrs[key].slice().sort();
            }

            if (JSON.stringify(ruleShowAttrs) !== JSON.stringify(selectedDefaultImageRuleShowAttrs)) {
                updatedAllAttrs.push(JSON.stringify(rule.show_attrs));
            }
        }

        console.log("TimeMachineDefaultImagesMasterDetailView.onDefaultImageRulesUpdate: updated all names: ", updatedAllNames, "selected default image rule name: ", selectedDefaultImageRule.name, "updated all attrs: ", updatedAllAttrs, "selected default image show attrs: ", selectedDefaultImageRuleShowAttrs);
        setAllNames(updatedAllNames);
        setAllAttrs(updatedAllAttrs);
    }, [JSON.stringify(defaultImageRules), JSON.stringify(selectedDefaultImageRule)]);

    useMemo(function onUpdateNewClicked() {
        console.log(`TimeMachineDefaultImagesMasterDetailView.onUpdateNewClicked: effect: new was clicked, new value is ${newClicked}`);
        if (newClicked) {
            const updatedDefaultImageRules = defaultImageRules.filter(rule => rule.is_new === false).slice();
            const newDefaultImageRule = Object.assign({}, {
                name: "",
                description: "",
                index: defaultImageRules.length,
                show_attrs: {},
                is_new: true,
                i18n: {}
            });

            updatedDefaultImageRules.push(Object.assign({}, newDefaultImageRule));
            setDefaultImageRules(updatedDefaultImageRules);
            setSelectedDefaultImageRule(newDefaultImageRule);
            setUpdatedSelectedDefaultImageRule(newDefaultImageRule);
            console.log(`TimeMachineDefaultImagesMasterDetailView.onUpdateNewClicked: effect: new was clicked, new value is ${newClicked}, selected default image rule is`, selectedDefaultImageRule);
        } else {
            const updatedDefaultImageRules = defaultImageRules.filter(rule => rule.is_new !== true).slice();
            setDefaultImageRules(updatedDefaultImageRules);
        }
    }, [newClicked]);

    const onClickSave = (index, name, description, sport, instance, league_name, tournament_name, images) => {
        setErrorMessage("");
        setSuccessMessage("");
        setSavingDefaultImages(true);
        const imagesToSend = [];
        console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: default image rules: ", defaultImageRules);
        for (const setting of defaultImageRules) {
            if (setting.index !== index) {
                const settingToSend = Object.assign({}, setting);
                delete settingToSend.index;
                imagesToSend.push(settingToSend);
            } else {
                const settingToSend = {
                    name,
                    description,
                    show_attrs: {
                        ...sport.length > 0 && {sport},
                        ...instance.length > 0 && {feed_id: [instance]},
                        ...league_name.length > 0 && {league_name},
                        ...tournament_name.length > 0 && {tournament_name}
                    },
                    i18n: {}
                };
                for (const language of Object.keys(images.i18n)) {
                    settingToSend.i18n[language] = {};
                    console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: adding language: ", language);
                    for (const imageId of Object.keys(images.i18n[language])) {
                        console.log(`TimeMachineDefaultImagesMasterDetailView.onClickSave: adding image id ${imageId} for language ${language}:`, images.i18n[language][imageId]);
                        const imageUrl = images.i18n[language][imageId]?.url ?? "";
                        if (imageUrl !== imageNotFoundUrl) {
                            settingToSend.i18n[language][imageId] = images.i18n[language][imageId].url;
                        }
                    }
                    console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: added language: ", settingToSend.i18n[language]);
                }
                console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: pushing setting: ", settingToSend);
                imagesToSend.push(settingToSend);
            }
        }

        console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: sending images: ", imagesToSend);
        TimeMachineDataProvider.cmsSaveDefaultImages(imagesToSend).then(response => {
            console.log("TimeMachineDefaultImagesMasterDetailView.onClickSave: response when saving specific metadata: ", response);
            refreshDefaultImageRules();
            setSuccessMessage("Default images have been updated.");
            setNewClicked(false);
        }).catch(error => {
            console.error("TimeMachineDefaultImagesMasterDetailView.onClickSave: ", error);
            setErrorMessage("There was an error saving the updated default images.");
        }).finally(() => {
            setSavingDefaultImages(false);
        });
    };

    const onClickDeleteRule = index => {
        console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteRule: deleting setting with index", index);
        setErrorMessage("");
        setSuccessMessage("");
        setSavingDefaultImages(true);
        const imagesToSend = [];
        const urlsToDelete = [];
        for (const setting of defaultImageRules) {
            if (setting.index !== index) {
                const settingToSend = Object.assign({}, setting);
                delete settingToSend.index;
                imagesToSend.push(settingToSend);
            } else {
                for (const key of Object.keys(imageSpecs)) {
                    if (setting.hasOwnProperty(key) && setting[key] !== imageNotFoundUrl) {
                        urlsToDelete.push(setting[key]);
                    }
                }
            }
        }

        console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteRule: sending images: ", imagesToSend);
        TimeMachineDataProvider.cmsSaveDefaultImages(imagesToSend).then(response => {
            console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteRule: response when saving specific metadata: ", response);
            refreshDefaultImageRules();
            setSelectedDefaultImageRule({});
            setUpdatedSelectedDefaultImageRule({});
            setSuccessMessage("Default images have been updated.");
        }).then(() => {
            for (const url of urlsToDelete) {
                TimeMachineDataProvider.cmsDeleteImage(url);
            }
        }).catch(error => {
            console.error("TimeMachineDefaultImagesMasterDetailView.onClickDeleteRule: ", error);
            setErrorMessage("There was an error deleting the updated default image setting.");
        }).finally(() => {
            setSavingDefaultImages(false);
        });
    };

    const onClickDeleteImage = (index, imageIdToUpdate, urlToDelete, language) => {
        setErrorMessage("");
        setSuccessMessage("");
        console.log(`TimeMachineDefaultImagesMasterDetailView.onClickDeleteImage: index ${index}, imageId ${imageIdToUpdate}, urlToDelete: ${urlToDelete}`);
        const updatedDefaultImageRules = [];
        for (const rule of originalDefaultImageRules) {
            if (rule.index === index) {
                const updatedRule = Object.assign({}, rule);
                if (updatedRule.i18n?.[language].hasOwnProperty(imageIdToUpdate)) {
                    updatedRule.i18n[language][imageIdToUpdate] = undefined;
                }
                updatedDefaultImageRules.push(updatedRule);
            } else {
                updatedDefaultImageRules.push(Object.assign({}, rule, {index: undefined}));
            }
        }

        console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteImage: updated default image rules: ", updatedDefaultImageRules);
        setSavingDefaultImages(true);

        TimeMachineDataProvider.cmsSaveDefaultImages(updatedDefaultImageRules).then(response => {
            console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteImage: response when saving specific metadata: ", response);
            if (urlToDelete.hasOwnProperty("url")) {
                TimeMachineDataProvider.cmsDeleteImage(urlToDelete.url).then(response => {
                    console.log("TimeMachineDefaultImagesMasterDetailView.onClickDeleteImage: response when deleting old url: ", response);
                });
            }
        }).then(() => {
            refreshDefaultImageRules();
        }).then(() => {
            setSuccessMessage("Default images have been updated.");
        }).catch(error => {
            console.error("TimeMachineDefaultImagesMasterDetailView.onClickDeleteImage: ", error);
            setErrorMessage("There was an error deleting the updated default image setting.");
        }).finally(() => {
            setSavingDefaultImages(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <TimeMachineDefaultImagesHelpModal
                isModalOpen={isInstructionsModalOpen}
                onOpenModal={() => setIsInstructionsModalOpen(true)}
                onCloseModal={() => setIsInstructionsModalOpen(false)}
            />
            <Grid.Column width={4}>
                <TimeMachineDefaultImagesSelector
                    defaultImageRules={defaultImageRules}
                    setDefaultImageRules={setDefaultImageRules}
                    selectedDefaultImageRule={selectedDefaultImageRule}
                    setSelectedDefaultImageRule={setSelectedDefaultImageRule}
                    setErrorMessage={setErrorMessage}
                    newClicked={newClicked}
                    setNewClicked={setNewClicked}
                    refreshDefaultImageRules={onClickRefresh}
                    loadingDefaultImages={loadingDefaultImages}
                    service={props.service}
                    module={props.module}
                    permissions={props.permissions}
                    user={props.user}
                    updatedSelectedDefaultImageRule={updatedSelectedDefaultImageRule}
                    setIsInstructionsModalOpen={setIsInstructionsModalOpen}
                    instanceLimits={instanceLimits}
                    editableInstances={editableInstances}
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <TimeMachineDefaultImagesDetailView
                    imageSpecs={imageSpecs}
                    metadata={metadata}
                    defaultMetadata={defaultMetadata}
                    newClicked={newClicked}
                    loadingDefaultImages={loadingDefaultImages}
                    savingDefaultImages={savingDefaultImages}
                    setSavingDefaultImages={setSavingDefaultImages}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    unsavedChanges={unsavedChanges}
                    selectedDefaultImageRule={selectedDefaultImageRule}
                    setUnsavedChanges={setUnsavedChanges}
                    updateDefaultImageRules={updateDefaultImageRules}
                    userCanEdit={userCanEdit}
                    allNames={allNames}
                    allAttrs={allAttrs}
                    service={props.service}
                    module={props.module}
                    permissions={props.permissions}
                    user={props.user}
                    instances={instances}
                    instanceLimits={instanceLimits}
                    editableInstances={editableInstances}
                    imageNotFoundUrl={imageNotFoundUrl}
                    TimeMachineDataProvider={TimeMachineDataProvider}
                    onClickSave={onClickSave}
                    onClickDeleteRule={onClickDeleteRule}
                    sports={sports}
                    leagueNames={leagueNames}
                    tournamentNames={tournamentNames}
                    originalDefaultImageRules={originalDefaultImageRules}
                    onClickDeleteImage={onClickDeleteImage}
                    updatedSelectedDefaultImageRule={updatedSelectedDefaultImageRule}
                    setUpdatedSelectedDefaultImageRule={setUpdatedSelectedDefaultImageRule}
                    userCanEditInstance={userCanEditInstance}
                    defaultImageRules={defaultImageRules}
                 />
            </Grid.Column>
        </Grid>
    );
};
