import React, {useState, useEffect, useMemo} from "react";
import {Accordion, Button, Container, Divider, Dropdown, Form, Grid, Icon, Image, Input, Item, Label, Message, Modal, Popup, Segment, Table, TextArea, Tab} from "semantic-ui-react";
import TimeMachineDefaultImagesUpdateImageModal from "./TimeMachineDefaultImagesUpdateImageModal";
import _ from "lodash";

export default function TimeMachineDefaultImagesDetailView(props) {
    const [index, setIndex] = useState(-1);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [originalImages, setOriginalImages] = useState({});
    const [updatedImages, setUpdatedImages] = useState({});
    const [initialShowAttrs, setInitialShowAttrs] = useState({});
    const [isRuleDeletionModalOpen, setIsRuleDeletionModalOpen] = useState(false);
    const [isImageDeletionModalOpen, setIsImageDeletionModalOpen] = useState(false);
    const [isUpdateImageModalOpen, setIsUpdateImageModalOpen] = useState(false);
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState("");
    const [remainingSportOptions, setRemainingSportOptions] = useState([]);
    const [selectedSports, setSelectedSports] = useState([]);
    const [activeAccordionPanels, setActiveAccordionPanels] = useState([]);
    const [sportsToAdd, setSportsToAdd] = useState([]);
    const [leagueNamesToAdd, setLeagueNamesToAdd] = useState([]);
    const [remainingLeagueNameOptions, setRemainingLeagueNameOptions] = useState([]);
    const [tournamentNamesToAdd, setTournamentNamesToAdd] = useState([]);
    const [remainingTournamentNameOptions, setRemainingTournamentNameOpions] = useState([]);
    const [selectedLeagueNames, setSelectedLeagueNames] = useState([]);
    const [selectedTournamentNames, setSelectedTournamentNames] = useState([]);
    const [languageTabs, setLanguageTabs] = useState([]);
    const [imageDeletionMetadata, setImageDeletionMetadata] = useState({});
    const [imageUpdateMetadata, setImageUpdateMetadata] = useState({});
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [hasDuplicateName, setHasDuplicateName] = useState(false);
    const [hasDuplicateAttrs, setHasDuplicateAttrs] = useState(false);
    const [containsExistingRule, setContainsExistingRule] = useState(false);
    const [languagesSupported, setLanguagesSupported] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");

    useMemo(function updateHasDuplicateAttrs() {
        let updatedHasDuplicateAttrs = false;
        let updatedContainsExistingRule = false;

        console.log("TimeMachineDefaultImagesDetailView.updateHasDuplicateAttrs: default image rules?", props.defaultImageRules);

        for (const rule of props.defaultImageRules) {
            const areInstancesEqual = (rule?.show_attrs?.feed_id ?? []).indexOf(selectedInstance) >= 0;
            const sportsAreContained = (!rule.show_attrs.sport && selectedSports.length < 1) || (selectedSports.length > 0 && (Array.isArray(rule.show_attrs.sport) && _.intersection(rule.show_attrs.sport, selectedSports).length === selectedSports.length));
            const ruleSportsAreContained = (!rule.show_attrs.sport && selectedSports.length < 1) || (selectedSports.length > 0 && (Array.isArray(rule.show_attrs.sport) && _.intersection(rule.show_attrs.sport, selectedSports).length === rule.show_attrs.sport.length));

            const leagueNamesAreContained = (!rule.show_attrs.league_name && selectedLeagueNames.length < 1) || (selectedLeagueNames.length > 0 && (Array.isArray(rule.show_attrs.league_name) && _.intersection(rule.show_attrs.league_name, selectedLeagueNames).length === selectedLeagueNames.length));
            const ruleLeagueNamesAreContained = (!rule.show_attrs.league_name && selectedLeagueNames.length < 1) || (selectedLeagueNames.length > 0 && (Array.isArray(rule.show_attrs.league_name) && _.intersection(rule.show_attrs.league_name, selectedLeagueNames).length === rule.show_attrs.league_name.length));

            const tournamentNamesAreContained = (!rule.show_attrs.tournament_name && selectedTournamentNames.length < 1) || (selectedTournamentNames.length > 0 && (Array.isArray(rule.show_attrs.tournament_name) && _.intersection(rule.show_attrs.tournament_name, selectedTournamentNames).length === selectedTournamentNames.length));
            const ruleTournamentNamesAreContained = (!rule.show_attrs.tournament_name && selectedTournamentNames.length < 1) || (selectedTournamentNames.length > 0 && (Array.isArray(rule.show_attrs.tournament_name) && _.intersection(rule.show_attrs.tournament_name, selectedTournamentNames).length === rule.show_attrs.tournament_name.length));
            console.log(`TimeMachineDefaultImagesDetailView.updateHasDuplicateAttrs: instances ${JSON.stringify(selectedInstance)} and ${JSON.stringify(rule.show_attrs.feed_id)} are equal? ${areInstancesEqual}; sports are contained? ${sportsAreContained}; league names are contained? ${leagueNamesAreContained}, tournament names are contained? ${tournamentNamesAreContained}, rule name: ${rule.name}`);
            console.log(`TimeMachineDefaultImagesDetailView.updateHasDuplicateAttrs: instances ${JSON.stringify(selectedInstance)} and ${JSON.stringify(rule.show_attrs.feed_id)} are equal? ${areInstancesEqual}; rule's sports are contained? ${ruleSportsAreContained}; rule's league names are contained? ${ruleLeagueNamesAreContained}, rule's tournament names are contained? ${ruleTournamentNamesAreContained} rule name: ${rule.name}`);
            if (areInstancesEqual && (sportsAreContained || ruleSportsAreContained) && (leagueNamesAreContained || ruleLeagueNamesAreContained) && (tournamentNamesAreContained || ruleTournamentNamesAreContained) && rule.index !== index) {
                updatedHasDuplicateAttrs = true;
                break;
            }

            if (areInstancesEqual && ruleSportsAreContained && ruleLeagueNamesAreContained && ruleTournamentNamesAreContained && rule.index !== index) {
                updatedContainsExistingRule = true;
                break;
            }
        }

        setHasDuplicateAttrs(updatedHasDuplicateAttrs);
        setContainsExistingRule(updatedContainsExistingRule);
    }, [selectedInstance, JSON.stringify(selectedSports), JSON.stringify(selectedLeagueNames), JSON.stringify(selectedTournamentNames), JSON.stringify(props.allAttrs), JSON.stringify(props.defaultImageRules)]);

    useMemo(function updateHasDuplicateName() {
        setHasDuplicateName(props.allNames.includes(name));
    }, [name, JSON.stringify(props.allNames)]);

    useMemo(() => {
        const enableSaving = ((props.newClicked && props.userCanEditInstance) || props.unsavedChanges) && !(name.length < 1 || hasDuplicateName || hasDuplicateAttrs || selectedInstance.length < 1 || containsExistingRule);
        const updatedDisableSaveButton = !(props.userCanEditInstance && enableSaving);
        console.log(`TimeMachineDefaultImagesDetailView.updateDisableSaveButton: save is disabled? ${updatedDisableSaveButton}`);
        setDisableSaveButton(updatedDisableSaveButton);
    }, [props.newClicked, JSON.stringify(props.selectedDefaultImageRule), props.unsavedChanges, hasDuplicateAttrs, hasDuplicateName, containsExistingRule, selectedInstance, name, props.userCanEditInstance]);

    useEffect(function updateImages() {
        console.log("image specs: ", props.imageSpecs, "default image rule: ", props.selectedDefaultImageRule);
        const images = {
            i18n: {}
        };
        for (const language of languagesSupported) {
            images.i18n[language] = {};
        }
        if (selectedInstance && props.metadata.hasOwnProperty(selectedInstance)) {
            console.log(`images supported: `, props.metadata[selectedInstance]?.["images_supported"], "languages supported: ", languagesSupported);

            for (const imageId of( props.metadata[selectedInstance]?.["images_supported"] ?? [])) {
                for (const language of languagesSupported) {
                    images.i18n[language][imageId] = Object.assign({}, props.imageSpecs[imageId]);
                }
            }
        }

        for (const language of Object.keys(images.i18n)) {
            for (const key of Object.keys(images.i18n[language])) {
                if (key === "i18n") {
                    continue;
                }
                if (!images.i18n[language].hasOwnProperty(key)) {
                    images.i18n[language][key] = {};
                }
                console.log(`selected default image rule for language ${language}: `, props.selectedDefaultImageRule?.i18n?.[language]);
                if (props.selectedDefaultImageRule?.i18n?.[language]?.hasOwnProperty(key) ?? false) {
                    images.i18n[language][key].url = props.selectedDefaultImageRule.i18n[language][key];
                } else {
                    images.i18n[language][key].url = props.imageNotFoundUrl
                }
            }
        }
        console.log("updated images: ", images);

        setOriginalImages(Object.assign({}, images));
        setUpdatedImages(Object.assign({}, images));

        const updatedActiveAccordionPanels = [];
        for (const _ of Object.keys(images)) {
            updatedActiveAccordionPanels.push(true);
        }

        setActiveAccordionPanels(updatedActiveAccordionPanels);
    }, [JSON.stringify(props.imageSpecs), JSON.stringify(props.selectedDefaultImageRule), props.loadingDefaultImages, selectedInstance, JSON.stringify(languagesSupported)]);

    useEffect(function updateLanguagesSupported() {
        setLanguagesSupported(props.metadata?.[selectedInstance]?.["languages_supported"] ?? props.defaultMetadata?.["languages_supported"] ?? ["en-US"]);
    }, [JSON.stringify(props.metadata), selectedInstance]);

    useMemo(function onUpdateSelectedDefaultImageRule() {
        console.log("effect: updated selected default image rule", props.selectedDefaultImageRule);
        setIndex(props.selectedDefaultImageRule.index);
        setInitialShowAttrs(Object.assign({}, props.selectedDefaultImageRule.hasOwnProperty("show_attrs") ? props.selectedDefaultImageRule.show_attrs : {}));
        setName(props.selectedDefaultImageRule.name || "");
        setDescription(props.selectedDefaultImageRule.description || "");
    }, [JSON.stringify(props.selectedDefaultImageRule)]);

    useEffect(() => {
        const updatedRemainingSportsOptions = [];
        for (const sport of props.sports) {
            if (!selectedSports.includes(sport)) {
                updatedRemainingSportsOptions.push({key: sport, text: sport, value: sport});
            }
        }
        setRemainingSportOptions(updatedRemainingSportsOptions);
    }, [JSON.stringify(props.sports), JSON.stringify(selectedSports)]);

    useEffect(() => {
        const updatedRemainingLeagueNameOptions = [];
        for (const leagueName of props.leagueNames) {
            if (!selectedLeagueNames.includes(leagueName)) {
                updatedRemainingLeagueNameOptions.push({key: leagueName, text: leagueName, value: leagueName});
            }
        }
        setRemainingLeagueNameOptions(updatedRemainingLeagueNameOptions);
    }, [JSON.stringify(props.leagueNames), JSON.stringify(selectedLeagueNames)]);

    useEffect(() => {
        const updatedRemainingTournamentNamesOptions = [];
        for (const tournamentName of props.tournamentNames) {
            if (!selectedTournamentNames.includes(tournamentName)) {
                updatedRemainingTournamentNamesOptions.push({key: tournamentName, text: tournamentName, value: tournamentName});
            }
        }
        setRemainingTournamentNameOpions(updatedRemainingTournamentNamesOptions);
    }, [JSON.stringify(props.tournamentNames), JSON.stringify(selectedTournamentNames)]);

    useEffect(() => {
        props.setUpdatedSelectedDefaultImageRule(Object.assign({}, {
            name,
            description,
            index,
            show_attrs: {
                instance: selectedInstance,
                sport: selectedSports,
                league_name: selectedLeagueNames,
                tournament_name: selectedTournamentNames
            }
        }));
    }, [name, description, selectedInstance, JSON.stringify(selectedSports), JSON.stringify(selectedLeagueNames), JSON.stringify(selectedTournamentNames), index]);

    useEffect(function updateInstanceOptions() {
        const updatedInstanceOptions = [];
        const instances = new Set();
        for (const instance of props.instances) {
            if (props.editableInstances === false || (Array.isArray(props.editableInstances) && props.editableInstances.includes(instance))) {
                console.log(`adding instance ${instance} to instances`);
                instances.add(instance);
            }
        }

        for (const instance of Array.from(instances)) {
            console.log(`adding instance ${instance} to instance options`);

            updatedInstanceOptions.push({key: instance, text: instance, value: instance});
        }
        console.log("updated instance options: ", updatedInstanceOptions, "instance props: ", props.instances, "instances: ", instances, "editable instances: ", props.editableInstances)
        setInstanceOptions(updatedInstanceOptions);
    },[JSON.stringify(props.instances), JSON.stringify(props.editableInstances)]);

    useMemo(function onUpdateInitialShowAttrs() {
        console.log("show attrs: ", initialShowAttrs);
        const updatedSelectedSports = [];
        let updatedSelectedInstance = "";
        const updatedSelectedLeagueNames = [];
        const updatedSelectedTournamentNames = [];

        if (initialShowAttrs.hasOwnProperty("sport")) {
            for (const sport of initialShowAttrs.sport) {
                updatedSelectedSports.push(sport);
            }
        }

        if (initialShowAttrs.hasOwnProperty("feed_id") && initialShowAttrs.feed_id.length > 0) {
            updatedSelectedInstance = initialShowAttrs.feed_id[0];
        }

        if (initialShowAttrs.hasOwnProperty("league_name")) {
            for (const leagueName of initialShowAttrs.league_name) {
                updatedSelectedLeagueNames.push(leagueName);
            }
        }

        if (initialShowAttrs.hasOwnProperty("tournament_name")) {
            for (const tournamentName of initialShowAttrs.tournament_name) {
                updatedSelectedTournamentNames.push(tournamentName);
            }
        }

        console.log("updated selected sports: ", updatedSelectedSports, "updated selected instance: ", updatedSelectedInstance, "updated selected league names: ", updatedSelectedLeagueNames, "updated selected tournament names: ", updatedSelectedTournamentNames);

        setSelectedSports(updatedSelectedSports);
        setSelectedInstance(updatedSelectedInstance);
        setSelectedLeagueNames(updatedSelectedLeagueNames);
        setSelectedTournamentNames(updatedSelectedTournamentNames);
    }, [JSON.stringify(initialShowAttrs)]);

    useMemo(function onUpdateImages() {
        const updatedLanguageTabs = [];
        for (const language of languagesSupported) {
            const updatedAccordionPanels = [];
            const updatedImagesForLanguage = updatedImages.i18n?.[language] ?? {};
            const originalImagesForLanguage = originalImages.i18n?.[language] ?? {};

            for (let i = 0; i < Object.keys(updatedImagesForLanguage).length; i++) {
                const imageId = Object.keys(updatedImagesForLanguage)[i];
                console.log(`updated images for language ${language}`, updatedImagesForLanguage);
                updatedAccordionPanels.push(
                    <Accordion.Panel
                        key={`image-${imageId}`}
                        title={updatedImagesForLanguage[imageId].name}
                        onTitleClick={
                            () => {
                                const updatedActiveAccordionPanels = activeAccordionPanels.slice();
                                updatedActiveAccordionPanels[i] = !updatedActiveAccordionPanels[i];
                                setActiveAccordionPanels(updatedActiveAccordionPanels);
                            }
                        }
                        active={activeAccordionPanels[i] === true}
                        styled
                        style={{hidden: activeAccordionPanels[i] === false}}
                        content={
                            <Segment>
                                <Grid>
                                    <Grid.Column width={10}>
                                        {!["", props.imageNotFoundUrl].includes(updatedImagesForLanguage[imageId].url) ? <a href={updatedImagesForLanguage[imageId].url} target="_blank">{updatedImagesForLanguage[imageId].url}</a> : <span>No Image Override</span>}
                                        <Table celled basic fixed>
                                            <Table.Body>
                                                {
                                                    Object.keys(updatedImagesForLanguage[imageId]).filter(key => !["url", "imageId", "name"].includes(key)).map(key => (
                                                        <Table.Row>
                                                            <Table.Cell>{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</Table.Cell>
                                                            <Table.Cell>{updatedImagesForLanguage[imageId][key]}</Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Item.Image
                                            label={updatedImagesForLanguage.hasOwnProperty(imageId) !== originalImagesForLanguage.hasOwnProperty(imageId) || updatedImagesForLanguage[imageId].url !== originalImagesForLanguage[imageId].url ? {ribbon: true, color: "blue", as: "a", content: "Updated"} : false}
                                            size="large"
                                            src={updatedImagesForLanguage.hasOwnProperty(imageId) && updatedImagesForLanguage[imageId].url ? updatedImagesForLanguage[imageId].url : props.imageNotFoundURL}
                                            fluid
                                        />
                                        <Button
                                            primary
                                            floated="left"
                                            disabled={!props.userCanEdit || !props.userCanEditInstance}
                                            onClick={
                                                () => {
                                                    setImageUpdateMetadata(Object.assign({imageId}, updatedImagesForLanguage[imageId]));
                                                    setIsUpdateImageModalOpen(true);
                                                    setSelectedLanguage(language);
                                                }
                                            }
                                        >Update Image</Button>
                                        <Button
                                            negative
                                            floated="right"
                                            disabled={!props.userCanEditInstance || !props.userCanEdit || !updatedImagesForLanguage.hasOwnProperty(imageId) || updatedImagesForLanguage[imageId].url === props.imageNotFoundUrl}
                                            onClick={() => {
                                                console.log(updatedImagesForLanguage[imageId]);
                                                setImageDeletionMetadata(Object.assign({}, {imageId, language}, updatedImagesForLanguage[imageId]));
                                                setIsImageDeletionModalOpen(true);
                                                setSelectedLanguage(language);
                                            }
                                            }
                                        >Remove Image</Button>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        }
                    />
                )
            }

            console.log(`updated accordion panels for language ${language}`, updatedAccordionPanels);

            updatedLanguageTabs.push(
                {
                    menuItem: language,
                    render: () => (
                        <Accordion
                            exclusive={false}
                            fluid
                            styled
                            panels={updatedAccordionPanels}
                        />
                    )
                }
            );
        }

        console.log("updated language tabs: ", updatedLanguageTabs);
        setLanguageTabs(updatedLanguageTabs);
    }, [JSON.stringify(updatedImages)]);

    const onClickDeleteRule = () => {
        props.onClickDeleteRule(index);
        setIsRuleDeletionModalOpen(false);
    };

    const onClickDeleteImage = () => {
        setIsImageDeletionModalOpen(false);
        const updatedImagesToDelete = imagesToDelete.slice();
        const url = imageDeletionMetadata?.url ?? null;
        if (url) {
            updatedImagesToDelete.push(url);
        }
        setImagesToDelete(updatedImagesToDelete);
        props.onClickDeleteImage(index, imageDeletionMetadata.imageId, imageDeletionMetadata.url, imageDeletionMetadata.language);
    };

    useEffect(function onImageUpdates() {
        console.log("TimeMachineDefaultImagesDetailView.onImageUpdates: selected default image rule: ", props.selectedDefaultImageRule, "updated images: ", updatedImages);
        let areImagesUpdated = false;
        for (const language of Object.keys(originalImages?.i18n ?? {})) {
            for (const key of Object.keys(originalImages.i18n[language] ?? {})) {
                areImagesUpdated ||= !updatedImages.i18n[language].hasOwnProperty(key) || updatedImages?.i18n?.[language]?.[key] !== originalImages.i18n?.[language]?.[key];
                if (areImagesUpdated) {
                    console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: image updated with key ${key} set from value ${originalImages.i18n?.[language]?.[key]} to value ${updatedImages.i18n?.[language].hasOwnProperty(key) ? updatedImages[key] : ""}`);
                    break;
                }
            }
        }

        for (const language of Object.keys(updatedImages.i18n ?? {})) {
            for (const key of Object.keys(updatedImages.i18n[language] ?? {})) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: original images for language ${language}: `, originalImages, "updated images: ", updatedImages);
                areImagesUpdated ||= !originalImages.i18n[language].hasOwnProperty(key) || updatedImages?.i18n?.[language]?.[key] !== originalImages.i18n?.[language]?.[key];
                if (areImagesUpdated) {
                    console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: image updated with key ${key} set from value ${originalImages?.i18n?.[language].hasOwnProperty(key) ? originalImages.i18n?.[language]?.[key] : ""} to value ${updatedImages.i18n?.[language]?.[key]}`);
                    break;
                }
            }
        }


        const isNameUpdated = props.selectedDefaultImageRule.name !== name;
        console.log(`was name updated? ${isNameUpdated}`);

        const isDescriptionUpdated = props.selectedDefaultImageRule.description !== description;
        console.log(`was description updated? ${isDescriptionUpdated}`);

        let areSportsUpdated = false;
        const initialSports = props.selectedDefaultImageRule.hasOwnProperty("show_attrs") && props.selectedDefaultImageRule.show_attrs.hasOwnProperty("sport") && Array.isArray(props.selectedDefaultImageRule.show_attrs.sport) ? props.selectedDefaultImageRule.show_attrs.sport : [];
        for (const sport of initialSports) {
            areSportsUpdated ||= !selectedSports.includes(sport);
            if (areSportsUpdated) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found old sport ${sport} not in new sport list`);
                break;
            }
        }

        if (!areSportsUpdated) {
            for (const sport of selectedSports) {
                areSportsUpdated ||= !initialSports.includes(sport);
                if (areSportsUpdated) {
                    console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found new sport ${sport} not in old sport list`);
                    break;
                }
            }
        }

        console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: were sports updated? ${areSportsUpdated}`, props.selectedDefaultImageRule.hasOwnProperty("show_attrs") ? props.selectedDefaultImageRule.show_attrs.sport : [], initialShowAttrs.sport, selectedSports);

        const initialInstance = props.selectedDefaultImageRule?.show_attrs?.feed_id?.[0] ?? "";
        const isInstanceUpdated = initialInstance !== selectedInstance;

        console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: were instances updated? ${isInstanceUpdated}`, props.selectedDefaultImageRule?.show_attrs?.feed_id ?? "no instance", initialShowAttrs.feed_id, selectedInstance);

        let areLeagueNamesUpdated = false;
        const initialLeagueNames = props.selectedDefaultImageRule.hasOwnProperty("show_attrs") && props.selectedDefaultImageRule.show_attrs.hasOwnProperty("league_name") && Array.isArray(props.selectedDefaultImageRule.show_attrs.league_name) ? props.selectedDefaultImageRule.show_attrs.league_name : [];
        for (const leagueName of selectedLeagueNames) {
            areLeagueNamesUpdated ||= !initialLeagueNames.includes(leagueName);
            if (areLeagueNamesUpdated) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found new league name ${leagueName} not in old league name list`);
                break;
            }
        }

        for (const leagueName of initialLeagueNames) {
            areLeagueNamesUpdated ||= !selectedLeagueNames.includes(leagueName);
            if (areLeagueNamesUpdated) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found old league name ${leagueName} not in new league name list`);
                break;
            }
        }

        let areTournamentNamesUpdated = false;
        const initialTournamentNames = props.selectedDefaultImageRule.hasOwnProperty("show_attrs") && props.selectedDefaultImageRule.show_attrs.hasOwnProperty("tournament_name") && Array.isArray(props.selectedDefaultImageRule.show_attrs.tournament_name) ? props.selectedDefaultImageRule.show_attrs.tournament_name : [];
        for (const tournamentName of selectedTournamentNames) {
            areTournamentNamesUpdated ||= !initialTournamentNames.includes(tournamentName);
            if (areTournamentNamesUpdated) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found new tournament name ${tournamentName} not in old tournament name list`);
                break;
            }
        }

        for (const tournamentName of initialTournamentNames) {
            areTournamentNamesUpdated ||= !selectedTournamentNames.includes(tournamentName);
            if (areTournamentNamesUpdated) {
                console.log(`TimeMachineDefaultImagesDetailView.onImageUpdates: found old tournament name ${tournamentName} not in new tournament name list`);
                break;
            }
        }

        console.log("TimeMachineDefaultImagesDetailView.onImageUpdates: has image been updated? ", areImagesUpdated);
        props.setUnsavedChanges(areImagesUpdated || isNameUpdated || isDescriptionUpdated || areSportsUpdated || isInstanceUpdated || areLeagueNamesUpdated || areTournamentNamesUpdated);
    }, [JSON.stringify(props.selectedDefaultImageRule), JSON.stringify(updatedImages), name, description, selectedInstance, JSON.stringify(selectedSports), JSON.stringify(selectedLeagueNames), JSON.stringify(selectedTournamentNames)]);

    const updateImage = (language, imageId, url) => {
        console.log(`updating image with id ${imageId} to url ${url}, from updated images: `, updatedImages);
        const imageToDelete = updatedImages.i18n?.[language]?.[imageId]?.url ?? updatedImages.i18n?.[language]?.[imageId] ?? null;
        const updatedLanguage = Object.assign({}, updatedImages.i18n[language], {[imageId]: {url}});
        const updatedI18N = Object.assign({}, updatedImages.i18n, {[language]: updatedLanguage});
        const updatedUpdatedImages = Object.assign({}, updatedImages, {i18n: updatedI18N});
        setUpdatedImages(updatedUpdatedImages);
        console.log("TimeMachineDefaultImagesDetailView.updateImage: setting updated images: ", updatedUpdatedImages, "from updatedImages: ", updatedImages);
        setUpdatedImages(updatedUpdatedImages);

        if (imageToDelete && imageToDelete !== props.imageNotFoundUrl) {
            const updatedImagesToDelete = imagesToDelete.slice();
            updatedImagesToDelete.push(imageToDelete);
            setImagesToDelete(updatedImagesToDelete);
        }

        props.onClickSave(index, name, description, selectedSports, selectedInstance, selectedLeagueNames, selectedTournamentNames, updatedUpdatedImages);
    };

    useEffect(function onUpdateImagesToDelete() {
        for (const url of imagesToDelete) {
            props.TimeMachineDataProvider.cmsDeleteImage(url);
        }
    }, [JSON.stringify(imagesToDelete)]);

    const onClickSave = () => {
        props.onClickSave(index, name, description, selectedSports, selectedInstance, selectedLeagueNames, selectedTournamentNames, updatedImages);
    };

    return (
        <Container>
            <Modal
                onClose={() => setIsRuleDeletionModalOpen(false)}
                open={isRuleDeletionModalOpen}
                size="small"
            >
                <Modal.Header>Delete Selected Default Images Rule</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete the selected default images rule?
                </Modal.Content>
                <Modal.Actions>
                    <Button primary icon onClick={() => onClickDeleteRule()}><Icon name="trash alternate outline"/> Delete</Button>
                    <Button onClick={() => setIsRuleDeletionModalOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            <TimeMachineDefaultImagesUpdateImageModal
                isModalOpen={isUpdateImageModalOpen}
                onOpenModal={() => setIsUpdateImageModalOpen(true)}
                onCloseModal={() => setIsUpdateImageModalOpen(false)}
                imageMetadata={imageUpdateMetadata}
                TimeMachineDataProvider={props.TimeMachineDataProvider}
                selectedLanguage={selectedLanguage}
                updateImage={updateImage}
            />
            <Modal
                onClose={() => setIsImageDeletionModalOpen(false)}
                open={isImageDeletionModalOpen}
                size="small"
            >
                <Modal.Header>Delete Selected Default Image</Modal.Header>
                <Modal.Content>
                    Are you sure you want to delete the selected default image?
                    <Table celled fixed>
                        {
                            Object.keys(props.imageSpecs?.[imageDeletionMetadata.imageId] ?? {}).filter(key => !["imageId", "url"].includes(key)).map(key => (
                                <Table.Row>
                                    <Table.HeaderCell textAlign="right">{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</Table.HeaderCell>
                                    <Table.Cell>{(props.imageSpecs?.[imageDeletionMetadata.imageId] ?? {})?.[key] ?? ""}</Table.Cell>
                                </Table.Row>
                            ))
                        }
                    </Table>
                    <Image src={imageDeletionMetadata.url || ""} />
                </Modal.Content>
                <Modal.Actions>
                    <Button primary icon onClick={() => onClickDeleteImage()}><Icon name="trash alternate outline"/> Delete</Button>
                    <Button onClick={() => setIsImageDeletionModalOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            {
                props.savingDefaultImages ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving default image settings...</Message.Content>
                    </Message> :
                props.errorMessage ?
                    <Message icon color="red">
                        <Icon name="exclamation" />
                        <Message.Content>{props.errorMessage}</Message.Content>
                    </Message> :
                props.successMessage ?
                    <Message icon color="green">
                        <Icon name="save outline" />
                        <Message.Content>{props.successMessage}</Message.Content>
                    </Message> :
                    ""
            }
            {
                !props.savingDefaultImages && !props.loadingDefaultImages && Object.keys(props.selectedDefaultImageRule).length > 0 ?
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Popup
                                    open={props.userCanEdit && props.userCanEditInstance && props.unsavedChanges && (props.newClicked || Object.keys(props.selectedDefaultImageRule).length > 0 ? name.length > 0 && !hasDuplicateName && !hasDuplicateAttrs && !containsExistingRule && selectedInstance.length > 0 : true)}
                                    content="You have made unsaved changes."
                                    trigger={
                                        <Button
                                            icon
                                            color="green"
                                            onClick={() => onClickSave()}
                                            disabled={disableSaveButton}
                                        ><Icon name="save outline"/> Save Default Images Rules</Button>
                                    }
                                    position="right center"
                                />
                            </Form.Field>
                            <Form.Field>
                                {
                                    Object.keys(props.selectedDefaultImageRule).length > 0 && props.selectedDefaultImageRule.index !== undefined && props.userCanEdit && props.userCanEditInstance ?
                                        <Button
                                            icon
                                            color="red"
                                            onClick={() => setIsRuleDeletionModalOpen(true)}
                                            floated="right"
                                            disabled={!props.userCanEditInstance}
                                        ><Icon name="trash alternate outline"/> Delete Default Image Rule</Button> : ""
                                }
                            </Form.Field>
                        </Form.Group>
                    </Form> : ""
            }
            {
                props.loadingDefaultImages ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading default images...</Message.Content>
                    </Message> :
                    Object.keys(props.selectedDefaultImageRule).length < 1 ?
                        <Message icon color="yellow">
                            <Icon name="info" />
                            <Message.Content>Please select a rule or click "Create New" to continue.</Message.Content>
                        </Message> :
                    <Form>
                        <Form.Group widths="equal">
                            <Popup
                                trigger={
                                    <Form.Field
                                        label="Name"
                                        control={Input}
                                        value={name}
                                        onChange={(event, {value}) => setName(value)}
                                        readOnly={!props.userCanEdit || !props.userCanEditInstance}
                                    />
                                }
                                open={name.length < 1 || hasDuplicateName || hasDuplicateAttrs || containsExistingRule || selectedInstance.length < 1}
                                content={
                                    <Segment basic compact>
                                        <ul>
                                            {name.length < 1 ? <li>You must enter a unique name.</li> : ""}
                                            {selectedInstance.length < 1 ? <li>You must select an instance.</li> : ""}
                                            {hasDuplicateAttrs ? <li>This set of attributes is associated with an already-existing rule.</li> : ""}
                                            {containsExistingRule ? <li>This set of attributes contains all the attributes of an already-existing rule.</li> : ""}
                                            {hasDuplicateName ? <li>Default image settings require a unique name.</li> : ""}
                                        </ul>
                                    </Segment>
                            }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                label="Description"
                                control={TextArea}
                                value={description}
                                onChange={(event, {value}) => setDescription(value)}
                                readOnly={!props.userCanEdit || !props.userCanEditInstance}
                            />
                        </Form.Group>
                        <Segment basic style={{background: selectedInstance.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Instance</Divider>
                            {
                                props.userCanEdit && props.userCanEditInstance &&
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Instance"
                                        control={Dropdown}
                                        options={instanceOptions}
                                        value={selectedInstance}
                                        fluid
                                        clearable
                                        search
                                        selection
                                        onChange={(event, {value}) => setSelectedInstance(value)}
                                    />
                                </Form.Group>
                            }
                        </Segment>
                        <Segment basic style={{background: selectedSports.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Sports</Divider>
                            {
                                props.userCanEdit && props.userCanEditInstance &&
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Sports to Add"
                                        control={Dropdown}
                                        options={remainingSportOptions}
                                        value={sportsToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        allowAdditions
                                        onChange={(event, {value}) => setSportsToAdd(value)}
                                        onAddItem={(event, {value}) => setRemainingSportOptions(remainingSportOptions.concat([{
                                            key: value,
                                            text: value,
                                            value
                                        }]))}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedSports = sportsToAdd.concat(selectedSports);
                                            setSelectedSports(updatedSports);
                                            setSportsToAdd([]);
                                        }}
                                        content="Add Selected Sports"
                                        fluid
                                        disabled={!props.userCanEdit || !props.userCanEditInstance}
                                    />
                                </Form.Group>
                            }
                            {
                                selectedSports.map((sport, index) => {
                                    return <Label
                                        as="a"
                                        key={`sport-${sport}`}
                                        circular
                                        onClick={() => {
                                            let updatedSports = selectedSports.slice();
                                            updatedSports.splice(index, 1);
                                            setSelectedSports(updatedSports);
                                        }}
                                    >
                                        {sport}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedLeagueNames.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>League Name</Divider>
                            {
                                props.userCanEdit && props.userCanEditInstance &&
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select League Name to Add"
                                        control={Dropdown}
                                        options={remainingLeagueNameOptions}
                                        value={leagueNamesToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        allowAdditions
                                        onChange={(event, {value}) => setLeagueNamesToAdd(value)}
                                        onAddItem={(event, {value}) => setRemainingLeagueNameOptions(remainingLeagueNameOptions.concat([{
                                            key: value,
                                            text: value,
                                            value
                                        }]))}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedLeagueNames = leagueNamesToAdd.concat(selectedLeagueNames);
                                            setSelectedLeagueNames(updatedLeagueNames);
                                            setLeagueNamesToAdd([]);
                                        }}
                                        content="Add Selected League Names"
                                        fluid
                                        disabled={!props.userCanEdit || !props.userCanEditInstance}
                                    />
                                </Form.Group>
                            }
                            {
                                selectedLeagueNames.map((leagueName, index) => {
                                    return <Label
                                        as="a"
                                        key={`sport-${leagueName}`}
                                        circular
                                        onClick={() => {
                                            let updatedLeagueNames = selectedLeagueNames.slice();
                                            updatedLeagueNames.splice(index, 1);
                                            setSelectedLeagueNames(updatedLeagueNames);
                                        }}
                                    >
                                        {leagueName}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <Segment basic style={{background: selectedTournamentNames.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                            <Divider horizontal>Tournament Name</Divider>
                            {
                                props.userCanEdit && props.userCanEditInstance &&
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Tournament Name to Add"
                                        control={Dropdown}
                                        options={remainingTournamentNameOptions}
                                        value={tournamentNamesToAdd}
                                        fluid
                                        multiple
                                        clearable
                                        search
                                        selection
                                        allowAdditions
                                        onChange={(event, {value}) => setTournamentNamesToAdd(value)}
                                        onAddItem={(event, {value}) => setRemainingTournamentNameOpions(remainingTournamentNameOptions.concat([{
                                            key: value,
                                            text: value,
                                            value
                                        }]))}
                                    />
                                    <Form.Field
                                        label="&nbsp;"
                                        control={Button}
                                        onClick={() => {
                                            const updatedTournamentNames = tournamentNamesToAdd.concat(selectedTournamentNames);
                                            setSelectedTournamentNames(updatedTournamentNames);
                                            setTournamentNamesToAdd([]);
                                        }}
                                        content="Add Selected Tournament Names"
                                        fluid
                                        disabled={!props.userCanEdit || !props.userCanEditInstance}
                                    />
                                </Form.Group>
                            }
                            {
                                selectedTournamentNames.map((tournamentName, index) => {
                                    return <Label
                                        as="a"
                                        key={`sport-${tournamentName}`}
                                        circular
                                        onClick={() => {
                                            let updatedTournamentNames = selectedTournamentNames.slice();
                                            updatedTournamentNames.splice(index, 1);
                                            setSelectedTournamentNames(updatedTournamentNames);
                                        }}
                                    >
                                        {tournamentName}
                                        <Icon name="delete"/>
                                    </Label>
                                })
                            }
                        </Segment>
                        <>
                            <Divider horizontal>Images</Divider>
                            <Tab
                                panes={languageTabs}
                            />
                        </>
                    </Form>
            }
        </Container>
    );
};
