import React, {ReactElement, FC} from "react";
import {Button, Container, Header, Modal} from "semantic-ui-react";

type TimeMachineDefaultImagesHelpModalProps = {
    isModalOpen: boolean,
    onOpenModal: Function,
    onCloseModal: Function
};

const TimeMachineDefaultImagesHelpModal: FC<TimeMachineDefaultImagesHelpModalProps> = (props): ReactElement => {
    return (
        <Modal
            open={props.isModalOpen}
            onClose={() => props.onCloseModal()}
            onOpen={() => props.onOpenModal()}
            size="large"
            closeIcon
        >
            <Modal.Header>Instructions</Modal.Header>
            <Modal.Content>
                <p>
                    This module is for setting default images for given criteria that will be passed along to InFlow.
                    <br /><br />
                    A default image rule is chosen for a program when its attributes, such as instance, match the requirements for that rule.
                    If a rule has multiple attributes, such as instance and sport, all the attributes must match in order for the rule to be applied.
                    <br /><br />
                    If that program has its own images, or if there are image overrides in the Time Machine CMS that would apply, they will override default images.
                    <br /><br />
                    To update an existing rule, select it from the selector on the left and make updates on the right. To create a new rule, click on the "Create New" button,
                    give the new rule a name and description, select attributes to decide whether a program will have these default images, and upload the images.
                    <br /><br />
                    Currently, there are three criteria for default images to be applied to a program.
                    These are selected from dropdowns, though it is possible to input values that are not in the dropdown.
                    <br /><br />
                    These are the three attributes:
                </p>
                    <ul>
                        <li>
                            Instance: This is also known as channel in InFlow, Tenant in LET, and Feed ID in LMDB.
                            The values you can select from here are what are sent to InFlow.
                        </li>
                        <li>
                            Sport: This value comes from either LMDB or LET.
                        </li>
                        <li>
                            League Name: This is the league name as passed to us by LET.
                        </li>
                    </ul>
                <br /><br />
                <p>
                    After selecting the attributes that a show must match for the rule to be applied, you can upload the images for that rule.
                    Click on the "Update Image" button in order to upload a new image, or "Delete Image" to remove the current image from the rule.
                    <br /><br />
                    Note: Images uploaded should meet the width, height, and aspect ratio requirements displayed next to the image.
                </p>
                <h3>Restrictions</h3>
                <ul>
                    <li>Every rule must have at least one instance</li>
                    <li>Names cannot be duplicated between rules</li>
                    <li>New rules cannot be created which will either contain or be contained by already-existing rules</li>
                </ul>
                <br />
                <p>
                    This means that, for example, if there was a rule for the instance <i>max</i> and the sport <i>Soccer</i>, another rule could not be created for the instances <i>max</i> and <i>br</i> and the sport <i>Soccer</i>.
                    A separate rule could be created for Soccer for the max instance, or for Soccer games specific to the <i>MLS</i> league.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={()=> props.onCloseModal()}>Okay</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default TimeMachineDefaultImagesHelpModal;
